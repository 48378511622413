import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { SmartSubData, BasicCustomer } from 'src/app/shared/interfaces/smartsub-data.interface';
import { CustomerSearchState } from '../store/search.state';
import { FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Navigate } from '@ngxs/router-plugin';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CUSTOMER_INFO_SUMMARY_ROUTE } from 'src/app/constants';
import { ResetCustomerDetails } from 'src/app/customer-info-summary-page/store/actions/customer.actions';
import { ResetCustomerSearch, FetchFullProfileByParam, FetchCustomerDetailsByUUID, FetchCustomerDetailsByOrderNumber, FetchCustomerDetailsByIMSI, FetchCustomerDetailsByPassport, FetchCustomerDetailsByICCID } from '../store/search.actions';
import { isEmailCheck, isICCIDCheck, isIDNumberCheck, isIMSICheck, isNumberCheck, isPhoneCheck, isUUIDCheck } from 'src/app/Utils/helpers/is-match-checker.helper';
import { CoreState } from 'src/app/core/store/state/core.state';
@Component({
    selector: 'sf-customer-search-bar',
    templateUrl: './customer-search-bar.component.html',
    styleUrls: ['./customer-search-bar.component.scss'],
})
export class CustomerSearchBarComponent implements OnInit {

    @Select(CustomerInfoState.isLoading) isLoading$: Observable<boolean>;
    @Select(CustomerInfoState.getSmartsubData) smartsubData$: Observable<SmartSubData>;
    @Select(CustomerInfoState.getErrorMessage) error$: Observable<string>;

    @Select(CustomerSearchState.isLoading) isCustomerNamesLoading$: Observable<boolean>;
    @Select(CustomerSearchState.getCustomerList) customerList$: Observable<BasicCustomer[]>;
    @Select(CustomerSearchState.getErrorMessage) customerListError$: Observable<string>;
    @Select(CoreState.hasOneOfTheseRoles(['CCD.THIRD_PARTY.A'])) hasCCDRole$: Observable<boolean>;

    isSearching: boolean;
    isEmail = false;
    errorMessage: string;

    CCD_TESTS = []

    readonly spinner = faSpinner;

    public searchControl: FormControl;

    private ngDestroy$ = new Subject<void>();

    constructor(
        private store: Store,
        private router: Router,
        private fb: FormBuilder
    ) { }

    ngOnInit(): void {

      for (let accountNumber = 1; accountNumber < 81; accountNumber++) {
        this.CCD_TESTS.push({
          name: `refurbished ${accountNumber}`,
          email: `refurbished${accountNumber}@ccd.co.za`,
        })
      }

        this.searchControl = this.fb.control('');
        this.searchControl.valueChanges
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                takeUntil(this.ngDestroy$)
            )
            .subscribe({
                next: (query: string) => this.typing(query)
            });

        combineLatest([
            this.customerListError$,
            this.error$
        ])
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe({
                next: ([listError, error]) => (this.errorMessage = listError ?? error)
            });
    }

    async onNavigate(email: string) {
        this.router.navigate([CUSTOMER_INFO_SUMMARY_ROUTE, email], { queryParamsHandling: "merge" });
        // this.router.navigate([CUSTOMER_INFO_SUMMARY_ROUTE, email]);
        this.store.dispatch(new ResetCustomerSearch());
    }

    private formatSearchString(text: string) {
        return text?.toLowerCase()?.replace(/mailto:/, '')?.trim();
    }

    onSearchByParam(searchString: string, autoNavigate = false) {
        this.store.dispatch(new FetchFullProfileByParam(searchString, autoNavigate));
    }

    typing(query: string) {
        this.clearSmartsubData();
        this.isEmail = false;

        if (query.length === 0) {
            this.clear();
        }

        if (query.length <= 2) {
            return;
        }

        this.isSearching = true;
        this.errorMessage = null;
        const searchString = this.formatSearchString(query);

        if (isEmailCheck(searchString)) {
            this.isEmail = true;
            this.onSearchByParam(searchString);
        }
        else if (isPhoneCheck(searchString) || isIDNumberCheck(searchString)) {
            this.onSearchByParam(searchString);
        }
        else if (isUUIDCheck(searchString)) {
            this.store.dispatch(new FetchCustomerDetailsByUUID(searchString));
        }
        else if (isIMSICheck(searchString)) {
            this.store.dispatch(new FetchCustomerDetailsByIMSI(searchString));
        }
        else if (isICCIDCheck(searchString)) {
            this.store.dispatch(new FetchCustomerDetailsByICCID(searchString));
        }
        else if (isNumberCheck(searchString)) {
            this.store.dispatch(new FetchCustomerDetailsByOrderNumber(+searchString));
        }
        else {
            this.store.dispatch(new FetchCustomerDetailsByPassport(searchString));
        }
    }

    onCloseErrorMessage() {
        this.errorMessage = null;
    }

    gotoInfoSummaryPage() {
        /* Some customers do not have profiles yet, but do have open tickets.
           This will give the agent the option to still got to the customer-info-summary page
           even if the customer is not found.  */
        const searchString = this.searchControl.value;
        this.store.dispatch(new Navigate([`customer-info-summary/${searchString}`], { queryParamsHandling: "merge" }));
    }

    clear() {
        this.store.dispatch(new ResetCustomerSearch());
    }

    clearSmartsubData() {
        this.store.dispatch(new ResetCustomerDetails());
    }

    ngOnDestroy(): void {
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
        this.store.dispatch(new ResetCustomerSearch());
    }

}
