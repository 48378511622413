import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProfileService } from '../profile.service';
import { AuthService } from 'src/app/auth/auth.service';
import { AzureProfile } from '../assets/azure-profile.interface';
import { DEFAULT_PROFILE_IMAGE } from 'src/app/constants';
import { Select, Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import escalations_map from "../../../assets/json-files/escalations_map.json";
import { filter, takeUntil } from 'rxjs/operators';
import { CoreState } from 'src/app/core/store/state/core.state';
import { SF_AUTH_ROLES } from 'src/app/auth/assets/auth-role.config';
import { UpdateAgentRoles } from 'src/app/shared/components/agent/store/actions/agent.actions';
import FAQ_Scenarios from '../../../assets/json-files/faq_scenarios.json';
import { ToggleModalByName } from 'src/app/core/store/actions/modal.actions';
import { SetSelectedAnswerQuestion } from 'src/app/main-modals-container/store/actions/faq-scenarios.actions';
import { AgentVoiceQueueState } from 'src/app/sip-phone/store/state/agent-voice-queue-state';
import { QueueData } from 'src/app/sip-phone/services/sip-platform/assets/voice-queue-info.interface';
import { VoiceAuthState } from 'src/app/sip-phone/store/state/voice-auth.state';
import { AgentVoiceQueueActions } from 'src/app/sip-phone/store/actions/agent-voice-queue-actions';
import { ModalState } from 'src/app/core/store/state/modal.state';
import { FeatureFlagActions } from '../feature-flag/store/actions/feature-flag-actions';
import { AgentState } from 'src/app/shared/components/agent/store/state/agent.state';
import { SFValidators } from 'src/app/shared/functions/sf-validators';

//TODO: ViewModel

@Component({
    selector: 'sf-profile-side-nav',
    templateUrl: './profile-side-nav.component.html',
    styleUrls: ['./profile-side-nav.component.scss'],
})
export class ProfileSideNavComponent implements OnInit, OnDestroy {
    @Select(AgentVoiceQueueState.isLoading) voiceLoginLoading$: Observable<boolean>;
    @Select(VoiceAuthState.isLoading) voiceAuthLoading$: Observable<boolean>;
    @Select(AgentVoiceQueueState.getError) voiceLoginError$: Observable<string>;
    @Select(AgentVoiceQueueState.getLoginStatus) voiceLoginStatus$: Observable<string>;
    @Select(AgentVoiceQueueState.getAllQueues) allQueues$: Observable<QueueData>;
    @Select(AgentState.getLoggedOnTime) loggedOnTime$: Observable<string>;

    @Select(CoreState.getAgentRoles) agentRoles$: Observable<string[]>;
    @Select(ModalState.isModalOpen("feature_flag_modal")) isFeatureFlagModalOpen$: Observable<boolean>;

    private ngDestroy$ = new Subject<void>();
    open: boolean;

    profile: AzureProfile;
    profileImage: string | ArrayBuffer = DEFAULT_PROFILE_IMAGE;
    profileOpen = false;

    currentEnvironment = environment.current_env;
    canSwitchEnvironments = environment.can_switch_env;
    isQaEmail = false;

    supportTypes: string[];
    supportType = '';
    options: string[];
    questions: string[];
    isFAQ: boolean;
    answer: 'any';
    option: string;

    roleOptions: string[] = [];

    constructor(
        private authService: AuthService,
        private profileService: ProfileService,
        private store: Store,
    ) { }

    ngOnInit(): void {
        this.open = this.profileService.getSidenavOpen();
        this.profileService.sidenavOpenChanged
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe((bool) => {
                this.open = bool;
            });

        this.getProfile();

        this.setRoleChoices();

        this.store.dispatch(new FeatureFlagActions.Init());
    }

    private setRoleChoices() {

        // Adding this so that QA doesn't need to switch roles on azure
        this.store.select(CoreState.getAgentEmail)
          .pipe(
            filter(email => SFValidators.isDefined(email)),
            takeUntil(this.ngDestroy$)
          )
          .subscribe( email => {
            const agentEmail = email.toLowerCase()

            this.isQaEmail = [
              'tania@rain.co.za',
              'seshni.thathiah@rain.co.za',
              'noluthando.biyase@rain.co.za',
              'noluthando.mthini@rain.co.za'
            ].includes(agentEmail)

            if (this.canSwitchEnvironments || this.isQaEmail ) {
                this.roleOptions = Object.keys(escalations_map.escalations).map(
                    (key) => key,
                );
            }
          })

    }

    async setTeamRole(role: string) {
        if (!this.canSwitchEnvironments && !this.isQaEmail) {
            return;
        }

        const currentRoles = this.store.selectSnapshot(CoreState.getAgentRoles);
        if (!currentRoles?.length) {
            return;
        }

        const newRoles = [
            role,
            ...currentRoles.filter((r) => r in SF_AUTH_ROLES),
        ];
        this.store.dispatch(new UpdateAgentRoles(newRoles, this.isQaEmail));
    }

    getSupportTypes() {
        this.supportTypes = Object.keys(FAQ_Scenarios);
    }

    @HostListener('click', ['$event'])
    private clickOSutside(e: { target: HTMLElement }) {
        const target = e.target;

        if (target.classList.contains('overlay')) {
            this.close();
        }
    }

    async getProfile() {
        this.profile = await this.authService.getProfile();

        this.profileImage = await this.profileService.getprofilePhoto();
    }

    close() {
        this.profileService.closeSidenav();
    }

    toggleProfileSettings() {
        this.profileOpen = !this.profileOpen;
    }

    getOptions(supportType: string) {
        this.supportType = supportType;
        if (supportType === 'FAQs') {
            this.isFAQ = true;
        } else if (supportType === 'Scenarios') {
            this.isFAQ = false;
        }
        this.options = Object.keys(FAQ_Scenarios[supportType]);
    }

    getQuestions(option: string) {
        this.option = option;
        this.questions = Object.keys(FAQ_Scenarios[this.supportType][option]);
    }

    getAnswers(question: string) {
        if (this.isFAQ) {
            this.answer = FAQ_Scenarios[this.supportType][this.option][question];
        } else {
            this.answer = FAQ_Scenarios[this.supportType][question];
        }
        this.store.dispatch(
            new SetSelectedAnswerQuestion(question, this.answer),
        );
        this.store.dispatch(new ToggleModalByName('faq_scenarios_modal', true));
    }

    toggleEnvironment() {
        if (environment.can_switch_env) {
            const newEnv = environment.current_env === 'sit' ? 'prod' : 'sit';
            localStorage.setItem('saved_testing_env', newEnv);
            location.reload();
        }
    }

    retryVoiceLogin() {
        this.store.dispatch(new AgentVoiceQueueActions.RetryLogin(true));
    }

    openFeatureFlagModal() {
        this.store.dispatch(new ToggleModalByName("feature_flag_modal", true));
    }

    showCallbacks() {
        this.close();
        this.store.dispatch(new ToggleModalByName("upcoming_callback_modal", true));
    }

    ngOnDestroy() {
        this.ngDestroy$.next(null);
        this.ngDestroy$.complete();
    }
}
