<button
  class="multiToolTip"
  [style.width]="width"
  [style.height]="height"
  [disabled]="disabled"
  [type]="type"
  [style.minWidth]="minWidth"
  [class.accept-button]="isAcceptButton"
  [class.decline-button]="isDeclineButton"
  [class.solid]="isSolid"
  [class]="isPromo ? 'rain-button-promo' :'rain-button'"
>
  <ng-content></ng-content>
</button>